/** @jsx jsx */
import { jsx } from "theme-ui"
import { Link } from "gatsby"
import { RiArrowRightSLine } from "react-icons/ri"

import ChapterCard from "./chapter-card"

export default function BlogListHome(props) {
  const data = props.data
  const chapters = data.edges
    .map(edge => <ChapterCard key={edge.node.id} data={edge.node} />)
  return <ChapterMaker data={chapters} />
}

const ChapterMaker = ({ data }) => (
  <section className="home-posts">
    <div className="grids col-1 sm-2 lg-3">{data}</div>
    <Link
      className="button"
      to="/"
      sx={{
        variant: "variants.button",
      }}
    >
      На главную
      <span className="icon -right">
        <RiArrowRightSLine />
      </span>
    </Link>
  </section>
)
